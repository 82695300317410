export default {
  color: `beta`,
  fontWeight: `medium`,
  textDecoration: `none`,
  userSelect: `none`,
  whiteSpace: `pre`,
  transition: `all 250ms ease`,
  p: 0,
  ':visited': {
    color: 'beta'
  },
  ':hover': {
    color: 'betaDarker'
  }
}
