import primary from './primary'
import paper from './paper'
import paperLg from './paper-lg'
import paperMd from './paper-md'
import interactive from './interactive'

export default {
  primary,
  paper,
  'paper-lg': paperLg,
  'paper-md': paperMd,
  interactive
}
