import React from 'react'
import { Link as GLink } from 'gatsby'
import Sticky from 'react-sticky-el'
import { Container, Box, Flex, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Drawer from '@solid-ui-components/Drawer'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import { FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa'

const styles = {
  wrapper: {
    position: `relative`,
    zIndex: 10,
    '.nav-container': {
      bg: `headerActiveBg`,
      // position: `fixed`,
      transition: `all 250ms ease-in`,
      // overflow: `hidden`,
      py: 2
    },
    '.nav-container img': {
      // filter: `grayscale(1) invert(1) sepia(0)`
      height: '60px'
    },
    '.nav-sticky img': {
      // filter: `none`
    },
    '.nav-sticky .nav-container': {
      bg: `headerActiveBg`,
      boxShadow: `0 0 25px rgba(140,152,164,.25)`,
      py: [3, null, 2],
      '.button-group-link.level-1, button-group-link.level-1:visited': {
        color: `headerActiveColor`
      }
    },
    //Make buttons in header smaller
    '.button-group-button': {
      minWidth: 120,
      fontSize: 1,
      px: 3,
      py: 1
    }
  },
  header: {
    justifyContent: `space-between`,
    alignItems: `center`
    // height: [`6rem`, `7rem`], //prevent layout shift
  },
  logoContainer: {
    flexShrink: 0,
    mr: [null, null, 3, 5]
  },
  breaker: {
    display: [`block`, 'inline-block']
  },
  stickyPromo: {
    display: [`block`],
    minWidth: `auto`,
    flexGrow: 1,
    backgroundColor: `beta`,

    padding: [1, 1, 0, 0],
    textAlign: `center`,
    color: 'white',
    borderRadius: '0',
    fontWeight: 500,
    letterSpacing: `2px`
  },
  desktopMenu: {
    display: [`none`, null, null, `block`],
    minWidth: `auto`,
    flexGrow: 1
  },
  mobileMenu: {
    display: [`block`, null, null, `none`]
  }
}

const HeaderBlock01 = ({ content: { images, collection }, menuJustify }) => {
  return (
    <>
      <Sticky
        enabled='false'
        stickyClassName='nav-sticky'
        css={css(styles.wrapper)}
      >
        <Container variant='full'>
          <Flex sx={styles.header}>
            {collection && (
              <>
                <Box sx={styles.stickyPromo}>
                  <Reveal effect='fadeInDown'>
                    <Flex
                      sx={{
                        alignItems: `center`,
                        justifyContent: `center`
                      }}
                    >
                      <Box
                        sx={{
                          '& + &': {
                            ml: 1
                          }
                        }}
                      >
                        <Box sx={styles.breaker}>
                          <GLink
                            to='tel://+12247353522'
                            style={{ color: 'white', textDecoration: 'none' }}
                            target='_blank'
                            rel='noreferrer'
                          >
                            <FaPhoneAlt size={16} /> (224) 735-3522
                          </GLink>
                        </Box>

                        <Box sx={styles.breaker}>
                          <GLink
                            to='https://www.google.com/maps/place/Rand+Medical+Center/@42.0985394,-87.9600665,20z/data=!4m5!3m4!1s0x880fba4ce0a8eee3:0x8763654958c915e8!8m2!3d42.0984461!4d-87.9600838'
                            style={{ color: 'white', textDecoration: 'none' }}
                            target='_blank'
                            rel='noreferrer'
                          >
                            <FaMapMarkerAlt
                              size={16}
                              style={{ marginLeft: '1rem' }}
                            />
                            Address: 1925 E Rand Rd, Arlington Heights, IL
                          </GLink>
                        </Box>
                      </Box>
                    </Flex>
                  </Reveal>
                </Box>
              </>
            )}
          </Flex>
        </Container>
        <Container variant='full' className='nav-container'>
          <Container px='4'>
            <Flex sx={styles.header}>
              <Box sx={styles.logoContainer}>
                <GLink to='/'>
                  <ContentImages
                    content={{ images }}
                    sx={styles.image}
                    imageEffect='fadeIn'
                  />
                </GLink>
              </Box>
              {collection && (
                <>
                  <Box sx={styles.desktopMenu}>
                    <Reveal effect='fadeInDown'>
                      <Flex
                        sx={{
                          alignItems: `center`,
                          justifyContent: menuJustify
                        }}
                      >
                        {collection.map(
                          ({ buttons }, index) =>
                            buttons && (
                              <Box
                                key={`item-${index}`}
                                sx={{
                                  fontSize: 1,
                                  '& + &': {
                                    ml: 3
                                  }
                                }}
                              >
                                <ContentButtons content={buttons} />
                              </Box>
                            )
                        )}
                      </Flex>
                    </Reveal>
                  </Box>
                  <Box sx={styles.mobileMenu}>
                    <Drawer buttonStyle={{ svg: { size: 32 } }}>
                      {collection.map(
                        ({ buttons }, index) =>
                          buttons && (
                            <Box
                              key={`item-${index}`}
                              sx={{
                                fontSize: 2,
                                '.button-group-link.level-1, button-group-link.level-1:visited':
                                  {
                                    color: `headerActiveColor`
                                  }
                              }}
                            >
                              <ContentButtons
                                content={buttons}
                                variant='vertical'
                              />
                            </Box>
                          )
                      )}
                    </Drawer>
                  </Box>
                </>
              )}
            </Flex>
          </Container>
        </Container>
      </Sticky>
    </>
  )
}

HeaderBlock01.defaultProps = {
  menuJustify: `flex-end`
}

export default WithDefaultContent(HeaderBlock01)
