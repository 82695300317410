import React from 'react'
import { Link as GLink } from 'gatsby'
import { Container, Box, Flex } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  wrapper: {
    position: `relative`,
    bg: `footerBg`
  },
  footer: {
    flexDirection: [`column-reverse`, `row`],
    justifyContent: `space-between`,
    alignItems: `center`,
    alignItems: [`center`, `center`],
    py: 5
  },
  link: {
    display: `inline-flex`,
    color: `inherit`,
    fontWeight: `medium`,
    marginLeft: `1rem`,
    textDecoration: `none`,
    ':visited': {
      color: `inherit`
    },
    ':hover': {
      color: `inherit`
    },
    svg: {
      height: 24,
      ml: 2
    }
  }
}

const FooterBlock02 = ({ content: { images, collection } }) => {
  return (
    <Box sx={styles.wrapper}>
      <Container px='4'>
        <Flex sx={styles.footer}>
          <Box sx={{ minWidth: 200 }}>
            {/* <Box pb='1' mb='2' mt={[4, 0]}>
              <GLink to='/'>
                <ContentImages content={{ images }} imageEffect='fadeIn' />
              </GLink>
            </Box> */}
            <Box pt='2' mb={[2, 0]}>
              © {new Date().getFullYear()} Rand Medical Center, All Rights
              Reserved.
              <GLink to='/privacy-policy/' style={styles.link}>
                Privacy Policy
              </GLink>
            </Box>
          </Box>
          {collection?.map(
            ({ text, buttons }, index) =>
              buttons && (
                <Box key={`item-${index}`} mb={[3, 0]}>
                  <ContentText
                    content={text?.[0]}
                    variant='h5'
                    sx={{ display: [`none`, `block`] }}
                  />
                  <ContentButtons
                    content={buttons}
                    variant='horizontal'
                    wrapperStyles={{
                      flexDirection: [null, `column`],
                      flexWrap: `wrap`,
                      justifyContent: `center`
                    }}
                  />
                </Box>
              )
          )}
        </Flex>
      </Container>
    </Box>
  )
}

FooterBlock02.defaultProps = {
  menuJustify: `flex-end`
}

export default WithDefaultContent(FooterBlock02)
