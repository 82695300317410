import { merge } from 'theme-ui'
import typography from './typography'
import borderWidths from './borderWidths'
import breakpoints from './breakpoints'
import space from './space'
import sizes from './sizes'
import radii from './radii'
import shadows from './shadows'
import colors from './colors'
import styles from './styles'
import buttons from './buttons'
import links from './links'
import text from './text'
import cards from './cards'
import badges from './badges'
import forms from './forms'
import layout from './layout'
import global from './global'

// import themme
// @solid-ui-theme is an alias for @elegantstack/solid-ui-theme/src
// import theme from '@solid-ui-theme'
// import sizes
// import sizes from '@solid-ui-theme/sizes'

// export default {
//   ...theme,
//   sizes: {
//     ...sizes,
//     container: 1100
//   }
// }

export default merge(typography, {
  // theme,
  colors,
  breakpoints,
  sizes: {
    ...sizes,
    container: 1400
  },
  space,
  radii,
  shadows,
  borderWidths,
  styles,
  buttons,
  links,
  text,
  cards,
  badges,
  forms,
  layout,
  global
})
