import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import useSiteMetadata from '@blocks-helpers/useSiteMetadata'

const Seo = ({
  title,
  description,
  excerpt,
  meta,
  keywords,
  author,
  socialAccounts,
  thumbnail,
  siteUrl,
  indexed,
  uri,
  schemaMarkup
}) => {
  const site = useSiteMetadata()

  const social = (author && author.social) || site.social || []
  // const twitterHandle = socialAccounts.twitter
    // social.find(s => s.name && s.name.toLowerCase() === 'twitter') || {}

  let pageDescription = excerpt || description || site.description

  thumbnail =
    thumbnail && thumbnail.images?.fallback.src
      ? `${site.siteUrl}${thumbnail?.images?.fallback.src}`
      : null

  const thumbnailUrl = thumbnail
  // thumbnail &&
  // (thumbnail.startsWith('//')
  //   ? thumbnail
  //   : site.siteMetadata && `${site.siteMetadata}${thumbnail}`)

  /**
   * Meta Tags
   */

  const metaTags = [
    { itemprop: 'name', content: title || site.title },
    { itemprop: 'description', content: description || site.description },
    { name: 'description', content: pageDescription },

    { property: 'og:title', content: title || site.title },
    {
      property: 'og:description',
      content: pageDescription || site.description
    },
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: site.name },
    { property: 'og:image', content: thumbnailUrl || site.thumbnail },
    { property: 'og:url', content: `${siteUrl}${uri}` },

    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: site.name },
    { name: 'twitter:title', content: title || site.title },
    { name: 'twitter:image', content: thumbnailUrl || site.thumbnail },
    {
      name: 'twitter:description',
      content: pageDescription || site.description
    },
    // { name: 'twitter:creator', content: twitterHandle },
    { rel: 'canonical', href: `${siteUrl}${uri}` }
  ]

  if (!indexed) {
    metaTags.push({ name: 'robots', content: 'noindex,nofollow' })
  } else {
    metaTags.push({ name: 'robots', content: 'index,follow' })
  }

  if (keywords && keywords.length > 0) {
    metaTags.push({ name: 'keywords', content: keywords.join(', ') })
  }

  if (meta) {
    metaTags.push(meta)
  }

  metaTags.push({ name: 'initial-scale', content: '1.0' })

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      title={title}
      titleTemplate={`%s | ${site.title}`}
      meta={metaTags}
    >
      {schemaMarkup && (
        <script type='application/ld+json'>
          {JSON.stringify(schemaMarkup)}
        </script>
      )}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  title: ``,
  description: ``,
  excerpt: ``,
  meta: ``,
  keywords: ``,
  author: ``,
  thumbnail: {},
  siteUrl: ``,
  indexed: false,
  uri: ``,
  schemaMarkup: {}
}

Seo.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  description: PropTypes.string,
  excerpt: PropTypes.string,
  meta: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  author: PropTypes.string,
  thumbnail: PropTypes.object,
  siteUrl: PropTypes.string,
  indexed: PropTypes.bool,
  uri: PropTypes.string,
  schemaMarkup: PropTypes.object
}

export default Seo
